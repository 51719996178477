<template>
	<!--솔루션 페이지 - 암호-->
	<div class="solution_container">
		<!--컨텐츠 1: 솔루션 소개-->
		<!--content1-->
		<div id="solution_content1">
			<div class="solutioncontent__title">
				<h1>새로운 대수적 공간에서 정의한<br /><span>암호 스키마</span></h1>
			</div>

			<div class="solution_main_img">
				<img :src="crypto" class="solution__mainimg" />
			</div>
			<div class="solutioncontent__textbox">
				<p>
					암호 연구에 기반이 되는 Algebraic Structure의 연구와<br />새로운
					Algebraic Structure 상의 암호 스키마를 연구하고 있습니다.
				</p>
			</div>
		</div>
		<!--content1 end-->
		<!--content2-->
		<div id="solution_content2">
			<div class="solutioncontent__textbox">
				<h2>Cryptographic Schema Solution</h2>
				<img :src="quotes1" class="quotes1_crypto" />

				<p>
					Number Theory의 Elliptic Curve와 Modular Form의 연구를 통하여<br />새로운
					암호 스키마를 개발하고 있습니다.<br />또한 4차 산업혁명에 핵심이 되는
					동형 암호의 개념을 확장시키기 위하여<br />기존 Low Level의 Algebraic
					Structure에 Elliptic Curve와 Modular Form을<br />적용하여 High Level의
					동형 암호 개념을 확장하는 연구를 진행하고 있습니다.
				</p>
				<img :src="quotes2" class="quotes2_crypto" />
			</div>
		</div>
		<!--content2 end-->
		<!--content3-->
		<div id="solution_content3">
			<div class="content3_title">
				<h2>
					기술 아키텍쳐<br />
					<span>적용된 기술을 확인해 보세요</span>
				</h2>
				<p class="solution_content3_margin">
					기술력을 통하여 새로운 패러다임으로 여러분을 만나겠습니다
				</p>
				<div class="border"></div>
				<p>
					암호스키마에 대한 원천 기술 개발과<br />특허권 보유로뛰어난 보안력과
					라이선스 비용 절감의 장점을 지닌 기술
				</p>
			</div>
			<div class="architecture1">
				<h3>
					FCTS 암호화 서비스 모델<br />
					<span>FCTS API를 통한 암호화 솔루션 비즈니스 모델</span>
				</h3>

				<img :src="architecture1" />
			</div>
			<div class="architecture2">
				<h3>ECHC 동형분석 기법</h3>
				<img :src="architecture2" />
			</div>
		</div>
		<!--content3 end-->
		<!--content4-->
		<div
			id="solution_content4"
			:style="{ backgroundImage: `url(${this.content4bg})` }"
		>
			<div class="alignment">
				<div class="content4_title">
					<p>Features Solutions</p>
					<h1>솔루션 특장점</h1>
				</div>
				<div class="content4_box">
					<div class="box">
						<i class="fas fa-check-circle"></i>
						<p>환경에 맞는 암호 스키마 설정</p>
					</div>
					<div class="box">
						<i class="fas fa-check-circle"></i>
						<p>동형 구조 등을 갖는 훌륭한 성능</p>
					</div>
					<div class="box">
						<i class="fas fa-check-circle"></i>
						<p>특허권을 통한 비용 절감</p>
					</div>
				</div>
			</div>
		</div>
		<!--content4 end-->
		<!--content5-->
		<div id="solution_content5">
			<div class="content5_title">
				<p>Applied Solutions</p>
				<h2>솔루션 활용</h2>
				<div class="border"></div>
			</div>
			<div class="solution_content5_box">
				<div class="box">
					<div class="image_box"><img :src="content5img1" /></div>
					<div class="text_box">
						<p>
							실시간으로 접속자, 데이터, 암호화에 대한<br />모든 로그를 한 번에
							확인, 점검 가능
						</p>
					</div>
				</div>
				<div class="box">
					<div class="image_box"><img :src="content5img2" /></div>
					<div class="text_box">
						<p>
							암호화 솔루션과 사용 환경을 제공하여<br />
							손쉽게 보안체계를 사용 가능
						</p>
					</div>
				</div>
				<div class="box">
					<div class="image_box"><img :src="content5img3" /></div>
					<div class="text_box">
						<p>
							FCTS에서 제공되는 각종 서비스를 사용자의<br />
							필요에 따라 추가하거나 변경 가능
						</p>
					</div>
				</div>
			</div>
		</div>
		<!--content5 end-->
		<!--content6-->
		<div id="solution_content6">
			<div class="content6_title">
				<div class="border-gradient"></div>
				<h1>FCTS만의 서비스를 확인해 보세요!</h1>
				<p>더 자세한 암호스키마 서비스를 알아보고 업무 효율을 높여보세요</p>
			</div>

			<div class="content6_box">
				<div class="box">
					<img v-scrollanimation :src="content6img1" class="img_left" />
					<div class="triangle_right"></div>
					<div class="text_box_right">
						<h1>MFC</h1>
						<h2>Modular Form Crytosystem</h2>
						<p>
							기존 ECC의 보안력을 향상한 암호 스키마입니다.<br />RSA, ECC에
							비하여 짧은 키 갈이와 빠른 연산속도를 가지며<br />전자서명, 키
							관리에 적합합니다.
						</p>
					</div>
				</div>

				<div class="box content6_box2">
					<div class="text_box_left">
						<h1>ECHC</h1>
						<h2>Elliptic Curve Homomorphlc Cryptosystem</h2>
						<p>
							기존 동형 암호의 속도 측면을 개선한 암호 스키마입니다. <br />
							동형 분석 등과 같이 암호화를 통한 분석 방법에 적합합니다.
						</p>
					</div>
					<div class="triangle-left"></div>
					<img :src="content6img2" class="img_right" />
				</div>

				<div class="box">
					<img v-scrollanimation :src="content6img3" class="img_left" />
					<div class="triangle_right"></div>
					<div class="text_box_right">
						<h1>PHC</h1>
						<h2>Pseudo Homomorphic Cryptosystem</h2>
						<p>
							MFC와 ECHC에 비하여 속도 측면은 조금 느리지만<br />
							보안력은 월등히 향상된 암호 스키마입니다.<br />
							강력한 데이터 보호가 필요한 범용적 환경에 적합합니다.
						</p>
					</div>
				</div>
			</div>
			<div class="content6_button">
				<button>
					<router-link to="/servicecrypto">암호 서비스 바로가기</router-link>
				</button>
			</div>
		</div>
		<!--content6 end-->
	</div>
</template>

<script>
import architecture1 from '@/assets/images/solution/crypto-architecture1.svg';
import architecture2 from '@/assets/images/solution/crypto-architecture2.svg';
import speciality1 from '../assets/images/service/speciality1.svg';
import speciality2 from '../assets/images/service/speciality2.svg';
import speciality3 from '../assets/images/service/speciality3.svg';
import num1 from '../assets/images/solution/num1.svg';
import num2 from '../assets/images/solution/num2.svg';
import num3 from '../assets/images/solution/num3.svg';
import crypto from '../assets/images/main/crypto.svg';
import phc from '../assets/images/solution/phc.jpg';
import echc from '../assets/images/solution/echc.jpg';
import mfc from '../assets/images/solution/mfc.jpg';
import quotes1 from '../assets/images/solution/quotes1.svg';
import quotes2 from '../assets/images/solution/quotes2.svg';
import solutioncontent4 from '../assets/images/solution/solutioncontent4_bg.svg';
import content4bg from '../assets/images/solution/content4_bg.svg';
import content5img1 from '../assets/images/solution/solutioncryptoimg1.svg';
import content5img2 from '../assets/images/solution/solutioncryptoimg2.svg';
import content5img3 from '../assets/images/solution/solutioncryptoimg3.svg';
import content6img1 from '../assets/images/solution/content6_cardimg1.png';
import content6img2 from '../assets/images/solution/content6_cardimg2.png';
import content6img3 from '../assets/images/solution/content6_cardimg3.png';

export default {
	data() {
		return {
			speciality1,
			speciality2,
			speciality3,
			num1,
			num2,
			num3,
			crypto,
			phc,
			echc,
			mfc,
			quotes1,
			quotes2,
			architecture1,
			architecture2,
			solutioncontent4,
			content4bg,
			content5img1,
			content5img2,
			content5img3,
			content6img1,
			content6img2,
			content6img3,
		};
	},
};
</script>

<style></style>
