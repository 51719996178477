<template>
	<!--솔루션 페이지 - 백신-->
	<div class="solution_container">
		<!--content1-->
		<div id="solution_content1">
			<div class="solutioncontent__title">
				<h1>유사도 알고리즘으로<br /><span>예측하는 백신</span></h1>
			</div>

			<div class="solution_main_img">
				<img :src="vaccine" class="solution__mainimg" />
			</div>
			<div class="solutioncontent__textbox">
				<p>
					유사도 알고리즘을 통해 신종 또는 변종 Malware뿐만 아니라<br />
					정상 코드와 악성코드를 분류할 수 있도록 모델을 설계하고<br />실제
					프레임워크에서도 활용 가능합니다.
				</p>
			</div>
		</div>
		<!--content1 end-->
		<!--content2-->
		<div id="solution_content2">
			<div class="solutioncontent__textbox">
				<h2>FC-Anti Virus</h2>
				<img :src="quotes1" class="quotes1" />
				<p>
					Malware data에 적합한 새로운 Metric 의 연구를 통하여<br />
					악성코드를 탐지할 수 있는 유사도 알고리즘 개발을 진행하였습니다.<br />
					또한, Topology space 연구를 통한 Malware data 분석을<br />
					통해 Malware를 예측할 수 있는 예측 모델 연구 및 개발을 완료하였습니다.
				</p>
				<img :src="quotes2" class="quotes2" />
			</div>
		</div>
		<!--content2 end-->

		<!--content3-->
		<div id="solution_content3">
			<div class="content3_title">
				<h2>
					기술 아키텍쳐<br />
					<span>적용된 기술을 확인해 보세요</span>
				</h2>
				<p class="solution_content3_margin">
					기술력을 통하여 새로운 패러다임으로 여러분을 만나겠습니다
				</p>
				<div class="border"></div>
				<p class="solution_content3_margin2">
					유사도 알고리즘을 추가한 검사를 통해<br />신종 · 변종 악성코드를 쉽게
					파악하고 변화에 빠르게 대응할 수 있는 백신 기술
				</p>
			</div>
			<div class="architecture1">
				<img :src="architecture1" />
			</div>
		</div>
		<!--content3 end-->
		<!--content4-->
		<div
			id="solution_content4"
			:style="{ backgroundImage: `url(${this.content4_bg})` }"
		>
			<div class="alignment">
				<div class="content4_title">
					<p>Features Solutions</p>
					<h1>솔루션 특장점</h1>
				</div>
				<div class="content4_box">
					<div class="box vaccine_box">
						<i class="fas fa-check-circle"></i>
						<p>악성코드 data에 알맞은 예측 모델 구현</p>
					</div>
					<div class="box vaccine_box">
						<i class="fas fa-check-circle"></i>
						<p>최적화된 알고리즘을 이용한 빠른 속도와 높은 정확도</p>
					</div>
				</div>
			</div>
		</div>
		<!--content4 end-->
		<!--content5-->
		<div id="solution_content5">
			<div class="content5_title">
				<p>Applied Solutions</p>
				<h2>솔루션 활용</h2>
				<div class="border"></div>
			</div>
			<div class="solution_content5_box">
				<div class="box">
					<div class="image_box"><img :src="content5img1" /></div>
					<div class="text_box">
						<p>악성코드의 API 시퀀스 및 빈도수 추출</p>
					</div>
				</div>
				<div class="box">
					<div class="image_box"><img :src="content5img2" /></div>
					<div class="text_box">
						<p>악성코드 식별 및 분류</p>
					</div>
				</div>
				<div class="box">
					<div class="image_box"><img :src="content5img3" /></div>
					<div class="text_box">
						<p>
							프로그램 파일의 바이너리 이미지를<br />
							이용한 CNN 알고리즘으로 악성코드 탐지
						</p>
					</div>
				</div>
			</div>
		</div>
		<!--content5 end-->
		<!--content6-->
		<div id="solution_content6">
			<div class="content6_title">
				<div class="border-gradient"></div>
				<h1>FCTS만의 서비스를 확인해 보세요!</h1>
				<p>더 자세한 암호스키마 서비스를 알아보고 업무 효율을 높여보세요</p>
			</div>

			<div class="content6_box">
				<div class="box">
					<img v-scrollanimation :src="content6img1" class="img_left" />
					<div class="triangle_right"></div>
					<div class="text_box_right">
						<h1>PE</h1>
						<h2>Malware Detecting Model</h2>
						<p>
							PE File에서 뽑아낸 특징 Data를 바탕으로<br />
							Malware 유무를 판단 및 탐지하는 서비스입니다.
						</p>
					</div>
				</div>

				<div class="box content6_box2">
					<div class="text_box_left">
						<h1>N-gram</h1>
						<h2>Malware Detecting Model</h2>
						<p>
							N-gram 분석 기법을 이용해 특징을 추출하고, 이를 바탕으로<br />
							Malware를 판단하고 탐지하는 서비스입니다.
						</p>
					</div>
					<div class="triangle-left"></div>
					<img :src="content6img2" class="img_right" />
				</div>

				<div class="box">
					<img v-scrollanimation :src="content6img3" class="img_left" />
					<div class="triangle_right"></div>
					<div class="text_box_right">
						<h1>Image</h1>
						<h2>Malware Detecting Model</h2>
						<p>
							프로그램 파일에 대한 바이너리(binary)를 이미지로<br />
							변환하고, 특징을 바탕으로 File에 대한 악성코드 유무를<br />
							판단 및 탐지하는 서비스입니다.
						</p>
					</div>
				</div>

				<div class="box content6_box2">
					<div class="text_box_left">
						<h1>FC-Anti Virus</h1>
						<h2>Vaccine</h2>
						<p>
							FCTS의 PE, N-gram, Image를 적용하여 신종 또는 변종<br />
							악성코드를 탐지하고, 이를 치료하는 백신 서비스입니다.
						</p>
					</div>
					<div class="triangle-left"></div>
					<img :src="content6img2" class="img_right" />
				</div>
			</div>
			<div class="content6_button">
				<button>
					<router-link to="/servicevaccine">백신 서비스 바로가기</router-link>
				</button>
			</div>
		</div>
		<!--content6 end-->
	</div>
</template>

<script>
import vaccineArchiImg from '@/assets/images/solution/vaccine-architecture.svg';
import speciality4 from '../assets/images/service/speciality4.svg';
import speciality5 from '../assets/images/service/speciality5.svg';
import speciality6 from '../assets/images/service/speciality6.svg';
import num1 from '../assets/images/solution/num1.svg';
import num2 from '../assets/images/solution/num2.svg';
import num3 from '../assets/images/solution/num3.svg';
import vaccine from '../assets/images/main/vaccine.svg';
import phc from '../assets/images/solution/phc.jpg';
import echc from '../assets/images/solution/echc.jpg';
import mfc from '../assets/images/solution/mfc.jpg';
import quotes1 from '../assets/images/solution/quotes1.svg';
import quotes2 from '../assets/images/solution/quotes2.svg';
import content4_bg from '../assets/images/solution/solutionvaccine_bg.svg';
import content5img1 from '../assets/images/solution/vaccineimg1.svg';
import content5img2 from '../assets/images/solution/vaccineimg2.svg';
import content5img3 from '../assets/images/solution/vaccineimg3.svg';
import content6img1 from '../assets/images/solution/vaccine_cardimg1.png';
import content6img2 from '../assets/images/solution/vaccine_cardimg2.png';
import content6img3 from '../assets/images/solution/vaccine_cardimg3.png';
import content6img4 from '../assets/images/solution/vaccine_cardimg4.png';
import architecture1 from '../assets/images/solution/vaccine_architecture.svg';

export default {
	data() {
		return {
			vaccineArchiImg,
			speciality4,
			speciality5,
			speciality6,
			num1,
			num2,
			num3,
			vaccine,
			phc,
			echc,
			mfc,
			quotes1,
			quotes2,
			content4_bg,
			content5img1,
			content5img2,
			content5img3,
			content6img1,
			content6img2,
			content6img3,
			content6img4,
			architecture1,
		};
	},
};
</script>

<style></style>
